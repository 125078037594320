import React from "react";
// import { Link } from 'gatsby';
import {
  Columns,
  Container,
  Footer,
  Level,
  Left,
  Right,
  Menu,
  MenuItem,
  Section,
} from "../components/bulma";

import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import linkedin from "../img/social/linkedin.svg";

// import appStore from '../img/AppStore.svg';
import googlePlay from "../img/GooglePlay.svg";
import finhub from "../img/finhub-logo.svg";

export default () => {
  return (
    <Footer>
      <Container>
        <div className="notification">
          <br />
          <Columns mobile multiline>
            {/* <div className="column is-6-mobile">
              <Menu label="why fastlabs">
                <MenuItem to="/features/smart-invoices">How it works</MenuItem>
                <MenuItem to="/features/subscriptions">
                  Key Competencies
                </MenuItem>
                <MenuItem to="/features/customer-profiles">Technology</MenuItem>
                <MenuItem to="/features/customer-profiles">Reviews</MenuItem>
              </Menu>
            </div> */}

            {/* <div className="column is-6-mobile">
              <Menu label="business cases">
                <MenuItem to="/why-business-needs-app">
                  Why Build a Mobile App
                </MenuItem>
                <MenuItem to="/business-cases/startups">Startups</MenuItem>
                <MenuItem to="/business-cases/small-business">
                  Small Business
                </MenuItem>
                <MenuItem to="/business-cases/corporate-innovation">
                  Corporate Innovation
                </MenuItem>
              </Menu>
            </div> */}

            {/* <div className="column is-6-mobile">
              <Menu label="Resources">
                <MenuItem to="/blog">Blog</MenuItem>
                <MenuItem to="/faq">FAQ</MenuItem>
              </Menu>
            </div> */}

            {/* <div className="column is-6-mobile">
              <Menu label="Company">
                <MenuItem to="/about">About</MenuItem>
                <MenuItem to="/news">News</MenuItem>
                <MenuItem to="/contact">Contact</MenuItem>
              </Menu>
            </div> */}
          </Columns>
          <br />
        </div>

        {/* <Level>
          <Left>
            <p>
              <a title="facebook" href="https://www.facebook.com/paymentninja/">
                <img
                  src={facebook}
                  alt="Facebook"
                  style={{ width: "2em", height: "2em", marginRight: 15 }}
                />
              </a>
              <a title="twitter" href="https://twitter.com/PaymentNinja">
                <img
                  src={twitter}
                  alt="Twitter"
                  style={{ width: "2em", height: "2em", marginRight: 15 }}
                />
              </a>
              <a
                title="linkedin"
                href="https://www.linkedin.com/company/payment-ninja/"
              >
                <img
                  src={linkedin}
                  alt="Twitter"
                  style={{ width: "2em", height: "2em", marginRight: 15 }}
                />
              </a>
              <a
                title="instagram"
                href="https://www.instagram.com/payment.ninja/"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: "2em", height: "2em" }}
                />
              </a>
            </p>
          </Left>
          <Item>
            <Link className="navbar-item" to="/terms">
              Terms of service
            </Link>
            <Link className="navbar-item" to="/privacy">
              Privacy
            </Link>
          </Item>
          <Right>
            <p>
              <a title="App Store" href="#">
                <img src={appStore} alt="App Store" />
              </a>{" "}
              <a
                title="Google Play"
                href="https://play.google.com/store/apps/details?id=ninja.payment.merchant"
              >
                <img className="fas fa-lg" src={googlePlay} alt="Google Play" />
              </a>
            </p>
          </Right>
        </Level> */}

        <Level>
          <Left>
            <p className="has-text-left is-size-7">
              <strong>© 2022 Fastlabs.</strong> A{" "}
              <a
                title="FinHub Global Ventures"
                href="https://finhub.vc/"
                target="_blanc"
              >
                FinHub Global Ventures
              </a>{" "}
              company.
              <br />
              All rights reserved. All product names, trademarks and registered
              trademarks are property of their respective owners.
              <br />
              All company, product and service names used in this website are
              for identification purposes only.
              <br />
              Use of these names, trademarks and brands does not imply
              endorsement.
            </p>
          </Left>
          <Right>
            <div
              style={{
                width: "120px",
                display: "inline-block",
              }}
            >
              <a
                title="FinHub Global Ventures"
                href="https://finhub.vc/"
                target="_blanc"
              >
                <img src={finhub} alt="FinHub Global Ventures logo" />
              </a>
            </div>
          </Right>
        </Level>
      </Container>
    </Footer>
  );
};
