import React from "react";
import { Link } from "gatsby";
import { Logo } from "./brand";

import { openPopupWidget } from "react-calendly";

const url = "https://calendly.com/fastlabs/free-consultation";

const Navbar = class extends React.Component {
  state = {
    active: false,
    navBarActiveClass: "",
  };

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  onClick = () => openPopupWidget({ url });

  render() {
    return (
      <div>
        <nav
          className="navbar is-fixed-top"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo">
                <div
                  style={{
                    width: "200px",
                  }}
                  className="size"
                >
                  <Logo />
                </div>
              </Link>

              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-end has-text-centered is-size-5">
                {/* <div class="navbar-item has-dropdown is-hoverable">
                  <a class="navbar-link">Why Fastlabs</a>

                  <div class="navbar-dropdown">
                    <Link className="navbar-item is-size-5" to="/how-it-works">
                      How it works
                    </Link>
                    <Link className="navbar-item is-size-5" to="/how-it-works">
                      Benefits
                    </Link>
                    <Link
                      className="navbar-item is-size-5"
                      to="/key-competencies"
                    >
                      Key Competencies
                    </Link>
                    <Link className="navbar-item is-size-5" to="/technology">
                      Technology
                    </Link>
                  </div>
                </div> */}
                <div class="navbar-item has-dropdown is-hoverable">
                  <a class="navbar-link">Business Cases</a>

                  <div class="navbar-dropdown">
                    <Link
                      className="navbar-item is-size-5"
                      to="/why-business-needs-mobile-app"
                    >
                      Why build a mobile app
                    </Link>
                    <Link
                      className="navbar-item is-size-5"
                      to="/business-cases/startups"
                    >
                      Startups
                    </Link>
                    <Link
                      className="navbar-item is-size-5"
                      to="/business-cases/small-business"
                    >
                      Small Business
                    </Link>
                    <Link
                      className="navbar-item is-size-5"
                      to="/business-cases/corporate-innovation"
                    >
                      Corporate Innovation
                    </Link>
                  </div>
                </div>

                <Link className="navbar-item" to="/pricing">
                  Pricing
                </Link>
                <Link className="navbar-item" to="/case-studies">
                  Case Studies
                </Link>
                <Link className="navbar-item" to="/reviews">
                  Reviews
                </Link>
              </div>
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons is-centered">
                    <button
                      onClick={this.onClick}
                      className="button is-primary is-rounded"
                    >
                      <strong>Talk to Expert</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* BOTTOM NAVBAR */}

        <nav
          className="navbar is-black is-fixed-bottom is-hidden-desktop"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <span className="navbar-item is-hidden-mobile"></span>
              <span className="navbar-item is-hidden-desktop">
                <div className="field is-grouped">
                  <p className="control">
                    <button
                      onClick={this.onClick}
                      className="button is-white is-rounded"
                    >
                      <strong>Free Consultation</strong>
                    </button>
                  </p>
                </div>
              </span>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default Navbar;
